import { SupabaseClient } from "@supabase/supabase-js";
import groupBy from "lodash/groupBy";
import type {
  AddInput,
  AddResponse,
  ApiRecordType,
  DeleteInput,
  DeleteResponse,
  QueryResponse,
  RemoveInput,
  RemoveResponse,
  ReviewsResponse,
  StorageFileUploadInput,
  UpdateInput,
  UpdateResponse,
  UpsertInput,
  UpsertResponse,
  User,
  UserHistoryResponse,
  UserNotificationsResponse
} from "types/apiTypes";
import {
  LookupEntry,
  MenuItem,
  PaginationState,
  SortItem,
  TableColumnType,
  TableFilterType,
  TableViewType
} from "types/baTypes";
import { RecordItem } from "types/common";
import { ACTIVITY_TABLE, CellType, DB_VIEWS, FILTER_OPERATOR, USER_TYPE } from "utils/constants";
import { checkTableFiltersValidation } from "utils/filterUtils";
import { CompositePKey, ExtendedSchema, TableSchema } from "utils/schema";
import { getCompositeKeysFromCols, getUpdatedFiltersForRecordType } from "utils/dataUtils";
import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { getTileGalleryData } from "./adminApi";
import {
  constructSupabaseColumnFilters,
  constructSupabaseSelect,
  constructSupabaseSlugFilter,
  generateFinalFilters
} from "./utils";

const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL;
const SUPABASE_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;

// Sets record to inactive
export const deleteRecordById = async (
  { id, tableName }: DeleteInput,
  supabaseClient: SupabaseClient
): Promise<DeleteResponse> => {
  const { error, status } = await supabaseClient.from(tableName).update({ is_active: false }).eq("id", id);
  return { data: null, error, status };
};

// Deletes record from DB
export const removeRecordById = async (
  { id, tableName }: RemoveInput,
  supabaseClient: SupabaseClient
): Promise<RemoveResponse> => {
  const { error, status } = await supabaseClient.from(tableName).delete().eq("id", id);
  return { data: null, error, status };
};

export const removeRecordByCompositePrimaryKey = async (
  { compositePrimaryKey, tableName }: RemoveInput,
  supabaseClient: SupabaseClient
): Promise<RemoveResponse> => {
  if (!compositePrimaryKey) {
    return { data: null, error: null };
  }

  let supabaseClientQuery = supabaseClient.from(tableName).delete();
  Object.keys(compositePrimaryKey).forEach((key) => {
    supabaseClientQuery = supabaseClientQuery.eq(key, compositePrimaryKey[key]);
  });
  const { error, status } = await supabaseClientQuery;
  return { data: null, error, status };
};

export const updateRecordById = async (
  { tableName, input }: UpdateInput,
  supabaseClient: SupabaseClient
): Promise<UpdateResponse> => {
  const { id, ...rest } = input;
  const { data, error } = await supabaseClient.from(tableName).update(rest).eq("id", id).select();
  return { data, error };
};

export const updateRecordByCompositeKey = async (
  { tableName, input }: UpdateInput,
  supabaseClient: SupabaseClient
): Promise<UpdateResponse> => {
  const { compositePrimaryKey, ...rest } = input;
  let supabaseSelectRef = supabaseClient.from(tableName).update(rest);
  Object.keys(compositePrimaryKey).forEach((key) => {
    supabaseSelectRef = supabaseSelectRef.eq(key, compositePrimaryKey[key]);
  });
  const { data, error } = await supabaseSelectRef.select();
  return { data, error };
};

export const addRecordToTable = async (
  { tableName, input }: AddInput,
  supabaseClient: SupabaseClient
): Promise<AddResponse> => {
  const { data, error, status } = await supabaseClient.from(tableName).insert(input).select();

  return { data, error, status };
};

export const upsertRecordToTable = async (
  { tableName, input }: UpsertInput,
  supabaseClient: SupabaseClient
): Promise<UpsertResponse> => {
  const { data, error, status } = await supabaseClient.from(tableName).upsert(input).select();

  return { data, error, status };
};

export const getTableData = async ({
  tableName,
  columns,
  tableFiltersOption,
  filters,
  sorting,
  supabaseClient,
  pagination,
  currentUser,
  globalSort,
  compositeKeysByTable,
  recordTypesData,
  extendedSchema,
  returnCountOnly = false,
  isAggregateFetch = false,
  skipOrgIdFilter = false
}: {
  tableName: string;
  columns: TableColumnType[];
  tableFiltersOption?: { filters: TableFilterType[] };
  filters?: TableFilterType[];
  sorting?: SortItem[];
  supabaseClient?: SupabaseClient | SupabaseClient<any, "audit", any>;
  pagination?: PaginationState;
  compositeKeysByTable?: { [key: string]: CompositePKey[] };
  currentUser?: User;
  globalSort?: SortItem[];
  recordTypesData?: ApiRecordType[];
  extendedSchema?: ExtendedSchema;
  returnCountOnly?: boolean;
  isAggregateFetch?: boolean;
  skipOrgIdFilter?: boolean; // used when syncing search data
}): Promise<QueryResponse> => {
  if (
    !tableName ||
    !checkTableFiltersValidation(tableFiltersOption) ||
    !supabaseClient ||
    (!skipOrgIdFilter && !currentUser?.org_id)
  )
    return { data: null, error: null };

  // Don't filter if there is no value (unless Boolean)
  // Remove OR Lookup filters as these are handled separately
  let finalFilters = filters?.length
    ? filters.filter((filter: RecordItem) => {
        const isFilterValidJsonBContains =
          [FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.EQUALS].includes(filter.filterOperator) &&
          filter.column?.dbType?.format === "jsonb" &&
          !!filter.filterValue &&
          filter.filterValue?.includes(".") &&
          !filter.filterValue?.endsWith(".");
        return (
          (filter.type === CellType.BOOLEAN ||
            [FILTER_OPERATOR.NOT_EMPTY, FILTER_OPERATOR.EMPTY].includes(filter.filterOperator) ||
            isFilterValidJsonBContains ||
            (!(
              [FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.EQUALS].includes(filter.filterOperator) &&
              filter.column?.dbType?.format === "jsonb"
            ) &&
              !!filter.filterValue)) &&
          (filter.filterOperator !== FILTER_OPERATOR.OR || filter.isBaseTableOrFilter)
        );
      })
    : [];

  const tableColumnsProperties = extendedSchema?.[tableName]?.properties;
  const finalTableFilters = tableFiltersOption?.filters?.length
    ? tableFiltersOption.filters
        .map((filter, filterIdx) => {
          if (filter.isInactive) {
            return null;
          }

          if (filter.filterLookupPath) {
            const updatedLookupPath: { [key: string]: LookupEntry } = {};
            Object.keys(filter.filterLookupPath).forEach((lookupLevel) => {
              const {
                lookupTableName = "",
                lookupForeignKey = "",
                lookupColumnLabel = ""
              } = filter.filterLookupPath?.[lookupLevel] || {};

              const tableName = lookupColumnLabel || lookupForeignKey || lookupTableName;
              updatedLookupPath[lookupLevel] = {
                ...filter.filterLookupPath?.[lookupLevel],
                lookupColumnLabel: lookupLevel === "0" ? `${tableName}_filter_${filterIdx}` : ""
              } as LookupEntry;
            });
            return {
              ...filter,
              filterLookupPath: updatedLookupPath
            };
          }
          if (filter.filterField && !filter.column && !filter.filterDbType) {
            const filterDbType = tableColumnsProperties?.[filter.filterField]?.type;
            return {
              ...filter,
              filterDbType
            };
          }
          return filter;
        })
        .filter(Boolean)
    : [];
  const allRecordTypes = recordTypesData?.map((recItem) => recItem.type);
  // Check for recordTypesData before sending columns
  // We skip validity check here because in a lot of cases columns are already appended with recordType lookup
  const finalColumns =
    columns?.length && allRecordTypes?.length
      ? (columns
          .map((col) => {
            if (allRecordTypes.includes(col.type)) {
              return appendRecordTypeLookupToCol(col, recordTypesData || [], tableName, extendedSchema || {}, true);
            }
            return col;
          })
          .filter(Boolean) as TableColumnType[])
      : columns;

  // update filters with correctColumns for recordType columns
  finalFilters = getUpdatedFiltersForRecordType({
    filters: finalFilters as TableFilterType[],
    recordTypesData,
    extendedSchema
  });

  // if baseTable is joinTable getCompositeKeyAttributes
  const baseTableCompositeKeys = extendedSchema?.[tableName]?.compositePk;

  const select = constructSupabaseSelect({
    columns: finalColumns,
    baseTableName: tableName,
    filters: finalFilters,
    compositeKeysByTable,
    tableFilters: finalTableFilters as TableFilterType[],
    baseTableCompositeKeys,
    sortLookupColumns: sorting?.filter((sort) => sort.col?.isLookup),
    isAggregateFetch
  }); // Filters have to update select

  const countType = tableName === ACTIVITY_TABLE ? "exact" : "estimated"; // Temporary fix till actual cause for inaccurate estimate for activity is found

  let supabaseSelectRef: any;
  if (returnCountOnly) {
    supabaseSelectRef = supabaseClient.from(tableName).select(select, { count: "exact", head: true });
  } else {
    // This is to handle a bug in Supabase currently where cannot pass the prefer header if null filtering relation column - (EMPTY) filter
    supabaseSelectRef = supabaseClient.from(tableName).select(select, { count: countType, head: false });
  }

  if (!!sorting?.length) {
    sorting.forEach((sort) => {
      if (!sort.col?.isLookup || sort.isViewSort) {
        supabaseSelectRef = supabaseSelectRef.order(sort.name, { ascending: !sort.desc, nullsFirst: false });
      }
      if (sort.col?.isLookup) {
        const firstLookupLevel = sort.col.lookupPath?.["0"];
        // Get lookup column name
        let sortColumnName = firstLookupLevel?.lookupDisplayColumn || firstLookupLevel?.lookupColumns?.[0];
        if (sortColumnName && firstLookupLevel?.lookupForeignKey) {
          sortColumnName = `${
            firstLookupLevel?.lookupColumnLabel || firstLookupLevel?.lookupForeignKey
          }(${sortColumnName})`;
          supabaseSelectRef = supabaseSelectRef.order(sortColumnName, {
            ascending: !sort.desc,
            nullsFirst: false,
            referenceTable: firstLookupLevel?.lookupTableName
          });
        }
      }
    });
  } else if (globalSort?.length) {
    globalSort.forEach((sort) => {
      if (sort.name) {
        supabaseSelectRef = supabaseSelectRef.order(sort.name as string, { ascending: !sort.desc, nullsFirst: false });
      } else {
        // Lookup global sort
        const sortCol = finalColumns?.find((col) => col.id === sort.id);
        if (sortCol && sortCol?.isLookup) {
          const firstLookupLevel = sortCol.lookupPath?.["0"];
          // Get lookup column name
          let sortColumnName = firstLookupLevel?.lookupDisplayColumn || firstLookupLevel?.lookupColumns?.[0];
          if (sortColumnName && firstLookupLevel?.lookupForeignKey) {
            sortColumnName = `${
              firstLookupLevel?.lookupColumnLabel || firstLookupLevel?.lookupForeignKey
            }(${sortColumnName})`;
            supabaseSelectRef = supabaseSelectRef.order(sortColumnName, {
              ascending: !sort.desc,
              nullsFirst: false,
              referenceTable: firstLookupLevel?.lookupTableName
            });
          }
        }
      }
    });
  }

  // Add column filters
  constructSupabaseColumnFilters(finalColumns, tableName, supabaseSelectRef);
  const finalAllFilters = finalFilters?.length ? [...finalFilters, ...finalTableFilters] : finalTableFilters;

  // This is slug based filtering only applied at table level
  const chainedSupabaseQuery = finalAllFilters?.length
    ? constructSupabaseSlugFilter(
        { filters: finalAllFilters as TableFilterType[] },
        tableName,
        supabaseSelectRef,
        currentUser
      )
    : supabaseSelectRef;

  let result =
    skipOrgIdFilter || DB_VIEWS.includes(tableName)
      ? chainedSupabaseQuery
      : chainedSupabaseQuery.eq("org_id", currentUser?.org_id);
  if (pagination) {
    if (!pagination.limit) {
      const pageIndex = pagination.currentPage - 1;
      const pageSize = pagination.pageSize;
      result = await chainedSupabaseQuery.range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1);
    } else {
      result = await chainedSupabaseQuery.limit(pagination.limit);
    }
  } else {
    result = await chainedSupabaseQuery;
  }

  const { data, error, count } = result;

  return { data, error, count, page: pagination?.currentPage };
};

export const getTableDataById = async (
  {
    tableName,
    id,
    organizationId,
    columns,
    public_id,
    filters,
    skipOrgIdCheck
  }: {
    tableName: string;
    id: string;
    organizationId?: string;
    columns?: Array<TableColumnType>;
    public_id?: string;
    filters?: TableFilterType[];
    source?: string; // Used to debug the source of this function call
    skipOrgIdCheck?: boolean;
  },
  supabaseClient?: SupabaseClient | SupabaseClient<any, "audit", any>,
  compositeKeysByTable?: { [key: string]: CompositePKey[] },
  extendedSchema?: ExtendedSchema
): Promise<RecordItem> => {
  if (!tableName || (!id && !public_id) || !supabaseClient || (!skipOrgIdCheck && !organizationId))
    return { data: null, error: null };
  // if baseTable is joinTable getCompositeKeyAttributes
  const baseTableCompositeKeys = extendedSchema?.[tableName]?.compositePk;

  const select = columns?.length
    ? constructSupabaseSelect({
        columns,
        baseTableName: tableName,
        filters: filters || [],
        compositeKeysByTable,
        baseTableCompositeKeys
      })
    : "*";
  let supabaseSelectRef = supabaseClient.from(tableName).select(select);

  if (!skipOrgIdCheck && organizationId) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }
  if (public_id) {
    supabaseSelectRef = supabaseSelectRef.eq("public_id", public_id);
  } else {
    supabaseSelectRef = supabaseSelectRef.eq("id", id);
  }
  if (columns?.length) {
    supabaseSelectRef = constructSupabaseColumnFilters(columns, tableName, supabaseSelectRef);
  }

  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const uploadFileToStorage = async (supabaseClient: SupabaseClient, input: StorageFileUploadInput) => {
  if (!input.bucket || !input.path) return { data: null, error: null };
  const { data, error } = await supabaseClient.storage.from(input.bucket).upload(input.path, input.file, {
    cacheControl: "31536000"
  });
  return { data, error };
};

export const getTableDataByColumnFilter = async ({
  tableName,
  columnName,
  value,
  additionalColumns,
  supabaseClient,
  options,
  organizationId,
  skipOrganizationId
}: {
  tableName: string;
  columnName: string;
  value: string | null | undefined;
  additionalColumns: TableColumnType[];
  supabaseClient: SupabaseClient;
  options?: { onlyFetchAdditionalColumns?: boolean }; // Will not fetch * only additionalColumns
  organizationId?: string;
  skipOrganizationId?: boolean;
}): Promise<any> => {
  if (!tableName || !columnName || value === undefined || (!organizationId && !skipOrganizationId))
    return { data: null, error: null };
  const select = additionalColumns?.length
    ? constructSupabaseSelect({
        columns: additionalColumns,
        baseTableName: tableName
      })
    : "";
  const finalSelect = options?.onlyFetchAdditionalColumns ? select : select ? `*,${select}` : "*";
  let supabaseSelectRef = supabaseClient.from(tableName).select(finalSelect).eq(columnName, value);
  if (!skipOrganizationId) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const getMentionsData = async (
  query: string,
  supabaseClient: SupabaseClient,
  orgId?: string
): Promise<RecordItem[]> => {
  if (!orgId) return [];
  return Promise.all([
    supabaseClient
      .from("people")
      .select("id, full_name, user_id, files_list_id(id,files_lists_files(is_featured,files(id,path,file_type)))")
      .ilike("full_name", `%${query}%`)
      .eq("is_active", true)
      .eq("type", USER_TYPE.STAFF)
      .eq("org_id", orgId)
      .limit(10),
    supabaseClient
      .from("companies")
      .select("id, name, files_list_id(id,files_lists_files(is_featured,files(id,path,file_type)))")
      .ilike("name", `%${query}%`)
      .eq("org_id", orgId)
      .limit(10),
    supabaseClient.from("spaces").select("id, icon, name").ilike("name", `%${query}%`).eq("org_id", orgId).limit(10),
    supabaseClient
      .from("projects")
      .select(
        "id, place_id!inner(full_address, address_line_1, address_line_2, city, state, zip_code),files_list_id(id,files_lists_files(is_featured,files(id,path,file_type)))"
      )
      .eq("org_id", orgId)
      .ilike("place_id.full_address", `%${query}%`)
      .limit(10)
  ]);
};

export const getAllTagsByType = async (
  supabaseClient: SupabaseClient,
  type: string,
  organizationId: string
): Promise<RecordItem> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient.from("tags").select().eq("type", type).eq("org_id", organizationId);
  return { data, error };
};

export const getMenuActionsByPageId = async (
  supabaseClient: SupabaseClient,
  pageId?: string,
  organizationId?: string
): Promise<RecordItem> => {
  if (!pageId || !organizationId) return Promise.resolve({ data: [], error: null });
  const { data, error } = await supabaseClient
    .from("ui_menu_items")
    .select(
      "*, ui_menu_actions(id, has_divider, sort, action_properties, wait_for_success, ui_action_id(name, type, icon))"
    )
    .eq("is_active", true)
    .eq("org_id", organizationId)
    .eq("ui_page_id", pageId);
  return { data, error };
};

export const getUserNotifications = async (
  supabaseClient: SupabaseClient,
  userId?: string
): Promise<UserNotificationsResponse> => {
  if (!userId) return Promise.resolve({ data: [], error: null });
  const { data, error } = await supabaseClient
    .from("user_notifications")
    .select()
    .eq("is_active", true)
    .eq("for_user_id", userId)
    .neq("type", "action");

  return { data, error };
};

export const getTableDataForAlgolia = async ({
  tableName,
  columns,
  viewFilters,
  supabaseClient,
  lastRecordId,
  returnCount = false,
  limit = 5000
}: {
  tableName: string;
  columns: TableColumnType[];
  viewFilters?: TableViewType;
  lastRecordId?: string;
  supabaseClient: SupabaseClient;
  returnCount?: boolean;
  limit?: number;
}) => {
  const select = constructSupabaseSelect({
    columns,
    baseTableName: tableName,
    tableFilters: viewFilters?.filters
  });
  let supabaseSelectRef;
  if (!returnCount) {
    supabaseSelectRef = supabaseClient.from(tableName).select(select);
  } else {
    supabaseSelectRef = supabaseClient.from(tableName).select(select, {
      count: "exact",
      head: true
    });
  }

  // Add column filters
  if (columns?.length) {
    supabaseSelectRef = constructSupabaseColumnFilters(columns, tableName, supabaseSelectRef);
  }

  // This is slug based filtering only applied at table level
  supabaseSelectRef = viewFilters?.filters?.length
    ? constructSupabaseSlugFilter({ filters: viewFilters.filters }, tableName, supabaseSelectRef)
    : supabaseSelectRef;

  // only get is_active records
  supabaseSelectRef = supabaseSelectRef.eq("is_active", true).order("id", { ascending: false }).limit(limit);

  if (lastRecordId) {
    supabaseSelectRef = supabaseSelectRef.lt("id", lastRecordId).limit(limit);
  }

  return await supabaseSelectRef;
};

export const getFirstRecordSorted = async ({
  tableName,
  column,
  pageFilters,
  supabaseClient,
  currentUser,
  extendedSchema
}: {
  tableName: string;
  column?: TableColumnType;
  pageFilters: { filters: TableFilterType[] };
  supabaseClient: SupabaseClient;
  currentUser?: User;
  extendedSchema?: { [key: string]: TableSchema };
}) => {
  if (!column?.name) return;

  const finalTableFilters = generateFinalFilters(pageFilters, extendedSchema?.[tableName]?.properties);
  const compositeKeysByTable = getCompositeKeysFromCols([column], extendedSchema);

  // if baseTable is joinTable getCompositeKeyAttributes
  const baseTableCompositeKeys = extendedSchema?.[tableName]?.compositePk;

  const select = constructSupabaseSelect({
    columns: [column],
    baseTableName: tableName,
    filters: [],
    compositeKeysByTable,
    tableFilters: finalTableFilters as TableFilterType[],
    baseTableCompositeKeys
  });

  let supabaseSelectRef = supabaseClient
    .from(tableName)
    .select(select)
    .eq("org_id", currentUser?.org_id)
    .order(column?.name, { ascending: true })
    .limit(1);

  supabaseSelectRef = constructSupabaseSlugFilter(
    { filters: finalTableFilters },
    tableName,
    supabaseSelectRef,
    currentUser
  );

  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

/**
 * This method currently is  specific to fetching menu_items added for tiles record
 * only and filters on table columns only.
 */
export const getRecordDataForMenuItems = async ({
  menuItems,
  supabaseClient,
  currentUser
}: {
  menuItems: MenuItem[];
  supabaseClient?: SupabaseClient;
  currentUser?: User;
}) => {
  const recordMenuItems: Array<{
    id: string;
    record_input: {
      tableName: string;
      columns: TableColumnType[];
      tableFiltersOption: { filters: TableFilterType[] };
      filters: TableFilterType[];
    } & RecordItem;
  }> = [];
  menuItems.forEach((item) => {
    if (item.record_config) {
      recordMenuItems.push({
        id: item.id,
        record_input: {
          tableName: item.record_config.table_name,
          tableFiltersOption: {
            filters: item.record_config.filters || []
          },
          columns: [],
          currentUser,
          filters: []
        }
      });
    }
  });
  const resp = await Promise.all(
    recordMenuItems.map((item) =>
      getTableData({
        ...item.record_input,
        currentUser,
        supabaseClient,
        pagination: { limit: 1, pageSize: 1, currentPage: 0 }
      })
    )
  );

  const recordRespForMenuItems: Array<{ id: string; record: RecordItem }> = [];
  recordMenuItems.forEach((menuItem, index) => {
    const recordResp = resp[index];
    if (!recordResp.error && recordResp.data?.length) {
      recordRespForMenuItems.push({
        id: menuItem.id,
        record: recordResp.data[0]
      });
    }
  });

  return recordRespForMenuItems;
};

export const getLockedRecordsData = async ({
  supabaseClient,
  tableName,
  pageId
}: {
  supabaseClient: SupabaseClient;
  tableName?: string;
  pageId?: string;
}) => {
  const supabaseSelectRef = supabaseClient.from("record_locks").select("*");
  if (tableName && pageId) {
    supabaseSelectRef.or(`table_name.eq.${tableName},page_id.eq.${pageId}`);
  } else {
    if (tableName) {
      supabaseSelectRef.eq("table_name", tableName);
    }
    if (pageId) {
      supabaseSelectRef.eq("page_id", pageId);
    }
  }
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const getStaticGalleryFiles = async (supabaseClient: SupabaseClient, spaceName?: string) => {
  const resp = await getTileGalleryData(supabaseClient, spaceName);
  return resp;
};

export const getStaticGalleryFilesSpaces = async (supabaseClient: SupabaseClient) => {
  const { data } = await getStaticGalleryFiles(supabaseClient);
  const filesSpaces = groupBy(data || [], "space_id.name");

  return {
    data: Object.keys(filesSpaces)
      .filter((fileSpace) => fileSpace !== "undefined")
      .map((fileSpace) => ({ name: fileSpace, icon: filesSpaces?.[fileSpace]?.[0]?.space_id?.icon }))
  };
};

export const getStaticReviewsForTiles = async (): Promise<ReviewsResponse> => {
  const result = await fetch(
    `${SUPABASE_URL}/rest/v1/reviews?select=*&website_display=eq.true&is_active=eq.true&order=sort.desc`,
    {
      method: "GET",
      headers: {
        ApiKey: SUPABASE_KEY as string
      }
    }
  ).then((res) => res.json());
  return { data: result, error: null };
};

export const getUserHistory = async (
  supabaseClient: SupabaseClient,
  userId?: string,
  organizationId?: string
): Promise<UserHistoryResponse> => {
  if (!userId || !organizationId) return { data: null, error: null };

  const { data, error } = await supabaseClient
    .from("user_history")
    .select("*")
    .eq("people_id", userId)
    .eq("org_id", organizationId)
    .order("created_at", { ascending: false });

  return { data, error };
};
