import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";

import type {
  ActionsResponse,
  AlgoliaSearchTableResponse,
  ApiPage,
  ApiRecordType,
  ApiView,
  ColumnTemplateResponse,
  ComponentsResponse,
  CustomViewsResponse,
  FilterTemplateResponse,
  MenuItemsResponse,
  MenuResponse,
  MenusResponse,
  PagesResponse,
  RecordTypesResponse,
  ReviewsResponse,
  TileComponentsResponse,
  TileGalleryResponse,
  Tiles,
  TilesResponse,
  BAPagesResponse,
  ApiPageViews,
  TableViewConfigResponse,
  BAPageResponse,
  AlgoliaSearchTablesResponse,
  ApiAlgoliaSearchTable,
  TableViewConfigsResponse,
  ActionResponse,
  OrganizationsResponse,
  Organization,
  OrganizationResponse
} from "types/apiTypes";
import { Page, PaginationState, SortItem, TableColumnType, TableFilterType } from "types/baTypes";
import { PAGE_QUERY_ADDITIONAL_PROP_TYPES, RecordItem } from "types/common";
import { BUILDAPPEAL_GALLERY_COMPONENT_PAGE_PATH, USER_TYPE, ViewOption } from "utils/constants";
import { generateFinalDataColumns, clientPeopleColumnsToFetch } from "utils/dataUtils";
import {
  mapApiAlgoliaSearchTableToAlgoliaSearchTable,
  mapApiPageToPage,
  mapApiPageViewsToPageViews,
  mapApiViewToTableViewConfig
} from "utils/pageUtils";
import { supabaseAnonKey, supabaseUrl } from "utils/supabase";
import { getTableData } from "./supabaseApi";
import { constructSupabaseSelect } from "./utils";

export const alternateSchemaCache: RecordItem = {}; // Reset managed by hook
const IS_LOCAL_DEV = !process.env.NEXT_PUBLIC_VERCEL_URL;
const IS_VERVEL_PREVIEW = process.env.VERCEL_ENV === "preview";

const getFinalUser = (peopleData: RecordItem) => {
  const user = peopleData?.[0];
  if (user?.files_list_id?.files_lists_files?.length) {
    const featuredImg = user.files_list_id?.files_lists_files.find((file: any) => file.is_featured);
    user.file = featuredImg ? featuredImg.files : user.files_list_id?.files_lists_files[0].files;
  }
  if (user?.organization?.name) {
    user.organizationName = user.organization.name;
  }
  return user;
};

export const getCurrentUser = async (supabaseClient: SupabaseClient, userId: string, isClient?: boolean) => {
  let select =
    "*,id,organization:org_id(id,name,subdomain),files_list_id(id,files_lists_files(id,is_featured,files(id,path,file_type,name,thumbnail_path,metadata->mimetype)))";
  if (isClient) {
    select += "," + clientPeopleColumnsToFetch.map((col) => col.name).join(",");
  }
  const { data, error } = await supabaseClient.from("people").select(select).eq("user_id", userId);

  return { data: data?.[0] ? getFinalUser(data) : null, error };
};

export const getAllMenuItems = async (
  supabaseClient: SupabaseClient,
  menuId: string,
  organizationId?: string
): Promise<MenuItemsResponse> => {
  if (!organizationId) {
    return { data: [], error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_menu_items")
    .select(
      "*, ui_page_id(id, title, path, metabase_dashboard_id, default_view, mobile_default_view, page_type, is_admin_page), ui_menu_actions(id, action_properties, ui_action_id(id, name, type, icon), wait_for_success, has_divider, sort)"
    )
    .eq("is_active", true)
    .eq("ui_menu_id", menuId)
    .eq("org_id", organizationId);
  return { data, error };
};

export const getPagesForAdmin = async (
  supabaseClient: SupabaseClient,
  userType?: USER_TYPE,
  organizationId?: string
): Promise<BAPagesResponse> => {
  if (!userType || !organizationId) {
    return { data: [], error: null };
  }
  let supabaseSelect = supabaseClient
    .from("pages")
    .select(
      `id,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),page_config,menu_id,is_active,created_in_path,default_view,has_error,default_empty_view,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,
      page_type,parent_id,path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type,
      columns:ui_pages_columns(*,
        column:columns_id(*,ui_pages_views_columns(*,
          view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,additional_config))))),
      views:ui_pages_views(id,default_column_section_id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*), 
        tabs:ui_pages_views_tabs(
        id,created_at,is_child,is_hidden, page_view_id,tab:ui_tabs(
          id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)))
      `
    )
    .eq("views.view.is_active", true)
    .eq("is_active", true)
    .or(`org_id.eq.${organizationId},is_admin_page.eq.true`);
  if (userType !== USER_TYPE.STAFF) {
    supabaseSelect = supabaseSelect.eq("user_type", userType);
  }
  const { data, error } = await supabaseSelect;
  // @ts-ignore
  return { data: data?.map((page) => mapApiPageToPage(page)), error };
};

export const getPagesLite = async (
  supabaseClient: SupabaseClient,
  userType?: USER_TYPE,
  organizationId?: string
): Promise<BAPagesResponse> => {
  if (!userType || !organizationId) {
    return { data: [], error: null };
  }
  let supabaseSelect = supabaseClient
    .from("pages")
    .select(
      `id,org_id,
      pageFilters:page_filters_id(id,
        columnRules:ui_column_condition(
          id,pageColumn:ui_page_column_id(
            *, column:columns_id(id)
          ),
          field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
        )
      ),
      page_config,menu_id,is_active,created_in_path,is_default_table_page,default_view,has_error,default_empty_view,head_title,is_admin_page,
      metabase_dashboard_id,mobile_default_view,
      page_type,parent_id,path,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type,
      views:ui_pages_views(id,pages_id,default_column_section_id,is_custom,
        view:views_id(id,view_type,is_active,is_default,additional_config),
        columns:ui_pages_views_columns(id),
        tabs:ui_pages_views_tabs(id,page_view_id,ui_tab_id,tab:ui_tabs(id,tab_page_id,add_page_id))
      )
      `
    )
    .eq("views.view.is_active", true)
    .eq("is_active", true)
    .eq("org_id", organizationId);
  if (userType !== USER_TYPE.STAFF) {
    supabaseSelect = supabaseSelect.eq("user_type", userType);
  }
  const { data, error } = await supabaseSelect;

  // @ts-ignore
  return { data: data?.map((page) => mapApiPageToPage(page)), error };
};

export const getDefaultTablePages = async (supabaseClient: SupabaseClient, orgId?: string) => {
  if (!orgId) {
    return { data: [], error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select("id,path,title,table_name,is_default_table_page,org_id")
    .eq("is_active", true)
    .eq("is_default_table_page", true)
    .eq("org_id", orgId);
  return { data, error };
};

export const getPagesColumnsByIds = async ({
  supabaseClient,
  pageIds,
  additionalDataProps,
  organizationId
}: {
  supabaseClient: SupabaseClient;
  pageIds: string[];
  additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
  organizationId?: string;
}): Promise<BAPagesResponse> => {
  if (!pageIds?.length || !organizationId) {
    return { data: [], error: null };
  }

  let select = `id,is_active,page_config,table_name,title,user_type,
  pageFilters:page_filters_id(
    id,
    columnRules:ui_column_condition(id,
      pageColumn:ui_page_column_id(
        *,
        column:columns_id(id)
      ),
      field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
    )
  ),
  columns:ui_pages_columns(*,
    lookupFilters:lookup_filter_id(
      id,
      columnRules:ui_column_condition(
        id,
        pageColumn:ui_page_column_id(*,
          column:columns_id(id)
        ),
        field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null
      )
    ),
    column:columns_id(
      *,
      ui_pages_views_columns(
        *,
        view:pages_views_id(
          id,is_custom,pages_id,
          views_id(id,view_type,is_default,additional_config)
        )
      )
    )
  )
  `;

  if (additionalDataProps?.includes("column_rules")) {
    select +=
      ",conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))";
  }
  if (additionalDataProps?.includes("sections")) {
    select += `,views:ui_pages_views(id,is_custom,default_column_section_id,
          sections:ui_column_sections!public_ui_column_sections_page_view_id_fkey(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
            pageViewColumns:ui_pages_views_column_sections(id,
              ui_pages_views_columns_id(columns_id),
              columns:ui_pages_views_columns(*,
                column:columns_id(id)
              )
            )
          ),
          view:views_id(*)
          )
        `;
  } else {
    select += `,
    views:ui_pages_views(id,is_custom,default_column_section_id,
        view:views_id(*),
        columns:ui_pages_views_columns(*,column:columns_id(id)))`;
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(select)
    .eq("views.view.is_active", true)
    .eq("org_id", organizationId)
    .in("id", pageIds);

  if (data?.length) {
    return {
      // @ts-ignore
      data: data?.map((page) => mapApiPageToPage(page)),
      error
    };
  }
  // @ts-ignore
  return { data, error };
};

export const getPageByPath = async (
  supabaseClient: SupabaseClient,
  path: string,
  userType: USER_TYPE,
  source?: string,
  organizationId?: string,
  skipOrganizationIdCheck?: boolean
): Promise<BAPageResponse> => {
  if (!path || !userType || (!skipOrganizationIdCheck && !organizationId)) {
    // @ts-ignore
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,is_active,page_config,menu_id,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,
      parentPage:parent_id(id,path),path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,is_user_visible,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,filter_db_type,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,filter_db_type,operator,value,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,
              section:ui_column_sections(id,title,description,webhook_url,config,sort,is_close,is_admin_only)
            ),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,additional_config)
          )
        ),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      views:ui_pages_views(id,is_custom,default_column_section_id,defaultColumnSection:default_column_section_id(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id))),pages_id,view:views_id(*),
        columns:ui_pages_views_columns(*,column:columns_id(id)),
        tabs:ui_pages_views_tabs(id,page_view_id,created_at,is_child,is_hidden,tab:ui_tabs(id,
          tab_page_id(id,title,path,table_name),
          default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),
          addPageFilters:add_page_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
          recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count))),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(id,ui_views_id, ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),
      algolia_search_table_id`
    )
    .eq("views.view.is_active", true)
    .eq("path", path.trim())
    .eq("user_type", userType)
    .eq("is_active", true);
  if (!skipOrganizationIdCheck) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }

  const { data, error } = await supabaseSelectRef;

  return {
    data: data?.[0] ? (mapApiPageToPage(data[0] as RecordItem as ApiPage) as Page) : (null as any),
    error: (error as PostgrestError) || (null as any),
    count: null as any,
    status: 200,
    statusText: ""
  };
};

export const getPageByPathForViewType = async ({
  supabaseClient,
  path,
  viewType,
  userType,
  organizationId,
  skipOrganizationIdCheck
}: {
  supabaseClient: SupabaseClient;
  path: string;
  viewType: ViewOption;
  userType: USER_TYPE;
  source?: string;
  organizationId?: string;
  skipOrganizationIdCheck?: boolean;
}): Promise<BAPageResponse> => {
  if (!path || !userType || !viewType || (!skipOrganizationIdCheck && !organizationId)) {
    // @ts-ignore
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,is_active,page_config,menu_id,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,
      parentPage:parent_id(id,path),path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,
      static_content,table_name,title,user_type,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,is_user_visible,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,filter_db_type,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,filter_db_type,operator,value,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,
              section:ui_column_sections(id,title,description,webhook_url,config,sort,is_close,is_admin_only)
            ),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,additional_config)
          )
        ),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      views:ui_pages_views!inner(id,is_custom,default_column_section_id,defaultColumnSection:default_column_section_id(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id))),pages_id,view:views_id!inner(*),
        columns:ui_pages_views_columns(*,column:columns_id(id)),
        tabs:ui_pages_views_tabs(id,page_view_id,created_at,is_child,is_hidden,tab:ui_tabs(id,
          tab_page_id(id,title,path,table_name),
          default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),
          addPageFilters:add_page_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
          recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count))),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(id,ui_views_id, ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),
      algolia_search_table_id`
    )
    .eq("views.view.is_active", true)
    .eq("views.view.view_type", viewType)
    .eq("path", path.trim())
    .eq("user_type", userType)
    .eq("is_active", true);
  if (!skipOrganizationIdCheck) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }

  const { data, error } = await supabaseSelectRef;

  return {
    data: data?.[0] ? (mapApiPageToPage(data[0] as RecordItem as ApiPage) as Page) : (null as any),
    error: (error as PostgrestError) || (null as any),
    count: null as any,
    status: 200,
    statusText: ""
  };
};

export const getPageViewSections = async ({
  supabaseClient,
  pageId,
  pageSlug,
  userType,
  source,
  organizationId
}: {
  supabaseClient: SupabaseClient;
  pageId?: string;
  pageSlug?: string;
  userType?: USER_TYPE;
  source?: string;
  organizationId?: string;
}): Promise<TableViewConfigsResponse> => {
  if ((!pageId && !pageSlug) || (!!pageSlug && !userType) || !organizationId) {
    // @ts-ignore
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,views:ui_pages_views(id,is_custom,default_column_section_id,pages_id,view:views_id(*),
      sections:ui_column_sections!public_ui_column_sections_page_view_id_fkey(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id)),
        filter:ui_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*,column:columns_id(id)),field,operator,value,filter_db_type,is_user_visible,option_value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      ))
   `
    )
    .eq("views.view.is_active", true)
    .is("views.sections.for_user_id", null)
    .eq("is_active", true)
    .eq("org_id", organizationId);

  if (pageId) {
    supabaseSelectRef = supabaseSelectRef.eq("id", pageId);
  } else if (pageSlug && userType) {
    supabaseSelectRef = supabaseSelectRef.eq("path", pageSlug).eq("user_type", userType);
  }
  const { data, error } = await supabaseSelectRef;

  return {
    data: data?.[0]?.views ? mapApiPageViewsToPageViews(data[0].views as RecordItem as ApiPageViews[]) : (null as any),
    error: (error as PostgrestError) || (null as any),
    count: null as any,
    status: 200,
    statusText: ""
  };
};

export const getPageUserViewSections = async ({
  supabaseClient,
  userId,
  pageId,
  organizationId
}: {
  supabaseClient: SupabaseClient;
  userId?: string;
  pageId?: string;
  organizationId?: string;
}) => {
  if (!userId || !pageId || !organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `id,views:ui_pages_views(id,is_custom,default_column_section_id,pages_id,view:views_id(*),
      sections:ui_column_sections!public_ui_column_sections_page_view_id_fkey(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
      pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id)),
      filter:ui_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*,column:columns_id(id)),field,operator,value,is_user_visible,filter_db_type,option_value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      ))
   `
    )
    .eq("views.view.is_active", true)
    .eq("views.sections.for_user_id", userId)
    .eq("id", pageId)
    .eq("is_active", true)
    .eq("org_id", organizationId);

  return { data: data?.[0] ? (data[0] as RecordItem as ApiPage) : null, error };
};

const constructPageLiteSelect = ({
  baseTableColumnNames,
  additionalColumns,
  additionalDataProps
}: {
  baseTableColumnNames?: string[];
  additionalColumns?: TableColumnType[];
  additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
}) => {
  let select =
    "id,path,title,table_name,is_default_table_page,user_type,head_title,page_config,menu_id,algolia_search_table_id,has_error,is_admin_page,org_id";
  if (baseTableColumnNames?.length) {
    select += "," + baseTableColumnNames.join(",");
  }

  if (additionalColumns?.length) {
    select +=
      "," +
      constructSupabaseSelect({
        columns: additionalColumns,
        baseTableName: "pages"
      });
  }

  if (additionalDataProps?.includes("views")) {
    select +=
      ",views:ui_pages_views(id,is_custom,view:views_id(*), sections:ui_column_sections!public_ui_column_sections_page_view_id_fkey(id,title,description,webhook_url,config,sort,is_close,is_admin_only,filter:ui_filters_id(id,columnRules:ui_column_condition(id,column_id,field,is_user_visible,option_value,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),columns:ui_pages_views_columns(*,column:columns_id(id)),tabs:ui_pages_views_tabs(id,is_child,is_hidden,created_at,tab:ui_tabs(id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,add_page_id(id,path,title,table_name),recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)))";
  }

  if (additionalDataProps?.includes("views_lite")) {
    select +=
      ",views:ui_pages_views(id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*,column:columns_id(id)), tabs:ui_pages_views_tabs(id,is_child,is_hidden,created_at,tab:ui_tabs(id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,add_page_id(id,path,title,table_name),recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count)))";
  }

  if (additionalDataProps?.includes("columns")) {
    select += `,columns:ui_pages_columns(*,
      lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,filter_db_type,lookup_column_name_field,filter_group,include_null)),
      columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
      column:columns_id(*,
        ui_pages_views_columns(*,columnSections:ui_pages_views_column_sections(id,section:ui_column_sections(id,title,description,webhook_url,config,sort,is_close,is_admin_only)),view:pages_views_id(id,pages_id,views_id(id,view_type,additional_config))),
      conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
    ))`;
  }

  if (additionalDataProps?.includes("columns_lite")) {
    select += `,
    columns:ui_pages_columns(*,
      lookupFilters:lookup_filter_id(id,
        columnRules:ui_column_condition(id,
          pageColumn:ui_page_column_id(*, 
            column:columns_id(id)
          ),
          field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
        )
      ),
      column:columns_id(*,
        ui_pages_views_columns(*,
          view:pages_views_id(id,pages_id,
            views_id(id,view_type,additional_config)
          )
        )
      )
    )`;
  }

  if (additionalDataProps?.includes("actions")) {
    select += `,ui_pages_actions(id, ui_views_id, ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu),sort_order, action_properties,page_id,visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)))`;
  }

  if (additionalDataProps?.includes("pageFilters")) {
    select += `,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))`;
  }
  if (additionalDataProps?.includes("column_rules")) {
    select += `,conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
    ui_pages_actions(id,ui_views_id, ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)))`;
  }
  return select;
};

export const getPageByPathLite = async (
  supabaseClient: SupabaseClient,
  {
    baseTableColumnNames,
    additionalColumns,
    path,
    userType,
    additionalDataProps = [],
    source,
    organizationId,
    skipOrganizationIdCheck
  }: {
    baseTableColumnNames?: string[];
    additionalColumns?: TableColumnType[];
    path: string;
    userType: USER_TYPE;
    additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
    source?: string; // Used to debug the source of the request
    organizationId?: string;
    skipOrganizationIdCheck?: boolean;
  }
): Promise<BAPageResponse> => {
  if (!path || !userType || path === "/" || (!skipOrganizationIdCheck && !organizationId)) {
    return { data: null, error: null };
  }
  const select = constructPageLiteSelect({ baseTableColumnNames, additionalColumns, additionalDataProps });
  let supabaseQueryRef = supabaseClient.from("pages").select(select).eq("path", path.trim()).eq("user_type", userType);

  if (additionalDataProps.includes("views") || additionalDataProps.includes("views_lite")) {
    supabaseQueryRef = supabaseQueryRef.eq("views.view.is_active", true);
  }
  if (!skipOrganizationIdCheck) {
    supabaseQueryRef = supabaseQueryRef.eq("org_id", organizationId);
  }
  const { data, error } = await supabaseQueryRef.eq("is_active", true);

  return { data: data?.[0] ? mapApiPageToPage(data[0] as unknown as ApiPage) : null, error };
};

export const getPageByIDLite = async (
  supabaseClient: SupabaseClient,
  {
    baseTableColumnNames,
    additionalColumns,
    id,
    additionalDataProps = [],
    source,
    organizationId,
    skipOrgIdCheck
  }: {
    baseTableColumnNames?: string[];
    additionalColumns?: TableColumnType[];
    id: string;
    additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
    source?: string; // Used to debug the source of the request
    organizationId?: string;
    skipOrgIdCheck?: boolean;
  }
): Promise<BAPageResponse> => {
  if (!id || (!skipOrgIdCheck && !organizationId)) {
    return { data: undefined, error: null };
  }

  const select = constructPageLiteSelect({ baseTableColumnNames, additionalColumns, additionalDataProps });
  let supabaseQueryRef = supabaseClient.from("pages").select(select).eq("id", id);

  if (additionalDataProps.includes("views") || additionalDataProps.includes("views_lite")) {
    supabaseQueryRef = supabaseQueryRef.eq("views.view.is_active", true);
  }
  if (organizationId) {
    supabaseQueryRef = supabaseQueryRef.eq("org_id", organizationId);
  }
  const { data, error } = await supabaseQueryRef.eq("is_active", true);
  return { data: data?.[0] ? mapApiPageToPage(data[0] as unknown as ApiPage) : undefined, error };
};

export const getPagesWithFiltersLinkedToSearchTableId = async (
  searchTableId: string,
  supabaseClient: SupabaseClient
): Promise<BAPagesResponse> => {
  const select = `id,path,title,is_admin_page,table_name,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null))`;
  const { data, error } = await supabaseClient
    .from("pages")
    .select(select)
    .eq("algolia_search_table_id", searchTableId);

  if (!data?.length || error) {
    return { data: null, error };
  }
  // @ts-ignore
  return { data: data.map((page) => mapApiPageToPage(page)), error };
};

// this function is used by the hook which is only used in page adminViewConfig
export const getPageDataByIdWithChildPages = async (
  supabaseClient: SupabaseClient,
  id: string,
  organizationId?: string
): Promise<PagesResponse> => {
  if (!id || !organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `id,is_active,org_id,is_default_table_page,page_config,menu_id,created_in_path,is_default_table_page,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,path,redirect_to,skip_record_sidebar_view,sort,static_content,table_name,title,user_type,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,is_user_visible,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,
                section:ui_column_sections(id,title,description,webhook_url,config,sort,is_close)
            ),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,additional_config)
          )
        ),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      parentPage:parent_id(id,path,title),
      views:ui_pages_views(id,default_column_section_id,is_custom,sections:ui_column_sections!public_ui_column_sections_page_view_id_fkey(id,title,description,webhook_url,config,sort,is_close,is_admin_only,filter:ui_filters_id(id,columnRules:ui_column_condition(id,column_id,field,operator,is_user_visible,option_value,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),view:views_id(*),columns:ui_pages_views_columns(*,column:columns_id(id)),
        tabs:ui_pages_views_tabs(id,created_at,is_child,is_hidden,tab:ui_tabs(id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          add_page_id(id,path,title,table_name),
          addPageFilters:add_page_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
          recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count))),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(id, ui_views_id, ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu),sort_order, action_properties,page_id,visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))),
      algolia_search_table_id`
    )
    .or(`id.eq.${id},parent_id.eq.${id}`)
    .is("views.sections.for_user_id", null)
    .eq("is_active", true);
  // @ts-ignore
  return { data, error };
};

export const getAllSiblingPages = async (supabaseClient: SupabaseClient, id?: string): Promise<PagesResponse> => {
  if (!id) {
    return { data: [], error: null };
  }

  const { data, error } = await supabaseClient
    .from("pages")
    .select("*, parent_id(id, title)")
    .eq("parent_id", id)
    .eq("is_active", true);

  return { data, error };
};

export const getPageDataById = async (
  supabaseClient: SupabaseClient,
  id: string,
  organizationId?: string,
  skipOrganizationIdCheck?: boolean
): Promise<RecordItem> => {
  if (!id || (!skipOrganizationIdCheck && !organizationId)) {
    return { data: null, error: null };
  }

  let supabaseSelectRef = supabaseClient
    .from("pages")
    .select(
      `id,is_default_table_page,is_active,page_config,menu_id,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,metabase_dashboard_id,mobile_default_view,page_type,parent_id,parentPage:parent_id(id,path),path,redirect_to,skip_record_sidebar_view,sort,static_content,table_name,title,user_type,
      pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_db_type,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      columns:ui_pages_columns(*,
        algoliaSearchTables:algolia_search_table_columns(id, algolia_search_table_id),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        addFilters:add_filter_id(id,columnRules:ui_column_condition(id,is_user_visible,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        column:columns_id(*,
          ui_pages_views_columns(*,
            columnSections:ui_pages_views_column_sections(id,section:ui_column_sections(id,title,description,webhook_url,config,sort,is_close,is_admin_only)),
            view:pages_views_id(id,is_custom,pages_id,views_id(id,view_type,is_default,additional_config))),
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      )),
      views:ui_pages_views(id,default_column_section_id,defaultColumnSection:default_column_section_id(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        pageViewColumns:ui_pages_views_column_sections(id,ui_pages_views_columns_id(columns_id))),is_custom,view:views_id(*),columns:ui_pages_views_columns(*,column:columns_id(id)),
        tabs:ui_pages_views_tabs(id,is_child,is_hidden,created_at,tab:ui_tabs(id,tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
        add_page_id(id,path,title,table_name),
        addPageFilters:add_page_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        recordid_source,sort,visibility,ui_menu_item_id,default_add_expanded,show_record_count))),
      conditionalFormatting:ui_column_rules(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
      ui_pages_actions(
        id, ui_views_id,
        ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu),
        sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed,
        conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))
      ),
      algolia_search_table_id`
    )
    .eq("views.view.is_active", true)
    .eq("id", id);
  if (!skipOrganizationIdCheck) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }

  const { data, error } = await supabaseSelectRef.single();
  if (!error && data) {
    return { data: mapApiPageToPage(data as RecordItem as ApiPage), error };
  }
  return { data, error };
};

export const getAllActions = async (
  supabaseClient: SupabaseClient,
  organizationId?: string,
  skipOrgIdCheck?: boolean
): Promise<ActionsResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient.from("ui_actions").select();
  if (!skipOrgIdCheck && organizationId) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

export const getActionByName = async (
  supabaseClient: SupabaseClient,
  name: string,
  organizationId?: string
): Promise<ActionResponse> => {
  if (!organizationId || !name) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_actions")
    .select()
    .eq("name", name)
    .eq("org_id", organizationId)
    .maybeSingle();

  return { data, error };
};

export const getAllMenus = async (supabaseClient: SupabaseClient, organizationId?: string): Promise<MenusResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_menus")
    .select("id,name,user_type,is_active")
    .eq("is_active", true)
    .eq("org_id", organizationId);
  return { data, error };
};

export const getAllColumnTemplates = async (
  supabaseClient: SupabaseClient,
  organizationId?: string
): Promise<ColumnTemplateResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient.from("ui_page_column_templates").select().eq("is_active", true);
  return { data, error };
};

export const getAllFilterTemplates = async (
  supabaseClient: SupabaseClient,
  organizationId?: string
): Promise<FilterTemplateResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient.from("ui_page_filter_templates").select().eq("is_active", true);
  return { data, error };
};

export const getPageSearchTable = async (
  supabaseClient: SupabaseClient,
  id: string,
  organizationId?: string
): Promise<BAPageResponse> => {
  if (!id || !organizationId) {
    return { data: undefined, error: null };
  }
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `id,
    algoliaSearchTablePage:algolia_search_table_id(*, 
      columns:algolia_search_table_columns(*, 
        column:ui_pages_columns_id(id,
          lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
          page_id(id,path,title),
          columns_id(*)
        )
      )
    )`
    )
    .eq("org_id", organizationId);

  return { data: data?.[0] ? mapApiPageToPage(data[0] as RecordItem as ApiPage) : undefined, error };
};

export const getSearchTableById = async (
  supabaseClient: SupabaseClient,
  id?: string,
  organizationId?: string,
  isAdminPage?: boolean
): Promise<AlgoliaSearchTableResponse> => {
  if (!id || (!isAdminPage && !organizationId)) return { data: null, error: null };
  let supabaseRef = supabaseClient
    .from("algolia_search_tables")
    .select(
      "*,linkedPage:linked_page_id(id,path,title,table_name), algoliaColumns:algolia_search_table_columns(*, pageColumn:ui_pages_columns_id(id,page_id(id,path,title),column:columns_id(id,header,is_lookup)))"
    )
    .eq("id", id)
    .eq("is_active", true);
  if (!isAdminPage) {
    supabaseRef = supabaseRef.eq("org_id", organizationId);
  }

  const { data, error } = await supabaseRef;
  //@ts-ignore
  return { data: data?.[0] ? mapApiAlgoliaSearchTableToAlgoliaSearchTable(data[0]) : null, error };
};

export const getSearchTableByIdWithColumns = async (
  supabaseClient: SupabaseClient,
  id?: string
): Promise<AlgoliaSearchTableResponse> => {
  if (!id) return { data: null, error: null };

  const { data, error } = await supabaseClient
    .from("algolia_search_tables")
    .select(
      `*,
      linkedPage:linked_page_id(id,path,title), 
      algoliaColumns:algolia_search_table_columns(*, 
        pageColumn:ui_pages_columns_id(id,
          lookupFilters:lookup_filter_id(id,
            columnRules:ui_column_condition(id,
              pageColumn:ui_page_column_id(*, 
                column:columns_id(id)
              ),
              field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          ),
          page_id(id,path,title),
          column:columns_id(*)
        )
      )`
    )
    .eq("id", id)
    .eq("is_active", true);

  //@ts-ignore
  return { data: data?.[0] ? mapApiAlgoliaSearchTableToAlgoliaSearchTable(data[0]) : null, error };
};

export const getSearchTables = async (
  supabaseClient: SupabaseClient,
  organizationId?: string
): Promise<AlgoliaSearchTablesResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("algolia_search_tables")
    .select("*,linkedPage:linked_page_id(id,path,title,table_name)")
    .eq("is_active", true);

  return {
    //@ts-ignore
    data: data?.length
      ? data.map((searchTable: ApiAlgoliaSearchTable) => mapApiAlgoliaSearchTableToAlgoliaSearchTable(searchTable))
      : null,
    error
  };
};

export const getTileByPublicId = async (
  supabaseClient: SupabaseClient,
  id?: string,
  slug?: string,
  isAdmin?: boolean
): Promise<TilesResponse> => {
  // Don't include id here as this is public data by default only add if requested
  let supabaseSelectRef = supabaseClient.from("tiles").select(
    `
    ${isAdmin ? `id,` : ``}
    company_id,
    public_id,
    invoice_id,
    is_active,
    is_template,
    parent_id,
    person_id,
    project_id,
    slug,
    status_id,
    name,
    type,
    document_title,
    prepared_by,
    prepared_for,
    intro_message,
    intro_display,
    metadata:metadata_id(*, og_file_id(id,path)),
    tiles_components:tiles_components (
      *,
      order,
      component_id:component_id (
        *
      )
    )
  `
  );

  if (id) {
    supabaseSelectRef = supabaseSelectRef.eq("public_id", id);
  } else if (slug) {
    supabaseSelectRef = supabaseSelectRef.eq("slug", slug.startsWith("/") ? slug : `/${slug}`);
  }
  if (!isAdmin) {
    supabaseSelectRef = supabaseSelectRef.eq("is_published", true);
  }
  const { data, error } = await supabaseSelectRef.eq("tiles_components.is_active", true);

  return { data: data as RecordItem[] as Tiles[], error };
};

export const getTileById = async (supabaseClient: SupabaseClient, id: string): Promise<TilesResponse> => {
  // this fetches using ID
  const { data, error } = await supabaseClient
    .from("tiles")
    .select(
      `
      id,
      company_id,
      public_id,
      invoice_id,
      is_active,
      is_template,
      parent_id,
      person_id,
      project_id,
      slug,
      status_id,
      name,
      type,
      document_title,
      prepared_by,
      prepared_for,
      intro_message,
      intro_display,
      metadata:metadata_id(*, og_file_id(id,path)),
      tiles_components:tiles_components (
        *,
        order,
        component_id:component_id (
          *
        )
      )
    `
    )
    .eq("id", id)
    .eq("tiles_components.is_active", true);
  return { data: data as RecordItem[] as Tiles[], error };
};

export const getComponents = async (supabaseClient: SupabaseClient): Promise<ComponentsResponse> => {
  const { data, error } = await supabaseClient.from("components").select("*");
  return { data, error };
};

export const getEditorComponents = async (supabaseClient: SupabaseClient): Promise<ComponentsResponse> => {
  const { data, error } = await supabaseClient.from("components").select("*").eq("use_in_editor", true);
  return { data, error };
};

export const updateSortNullValues = async ({
  tableName,
  column,
  filters,
  isJoinTable
}: {
  tableName: string;
  column: TableColumnType;
  filters?: { filters: TableFilterType[] };
  isJoinTable?: boolean;
}) => {
  const res = await fetch("/api/tabledata/update_sort_null_values", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ tableName, column, filters, isJoinTable })
  }).then((res) => res.json());

  return res;
};

export const getTileDataByPublicId = async (tileId: string): Promise<TilesResponse> => {
  if (!tileId) return Promise.resolve({ data: [], error: null });
  const result = await fetch(`/api/tiles/get_tile_by_public_id?tileId=${tileId}`).then((res) => res.json());
  return result;
};

export const getTileDataBySlug = async (tileSlug: string): Promise<TilesResponse> => {
  if (!tileSlug) return Promise.resolve({ data: [], error: null });
  const result = await fetch(`/api/tiles/get_tile_by_public_id?tileSlug=${tileSlug}`).then((res) => res.json());
  return result;
};

export const getAllComponents = async (
  supabaseClient: SupabaseClient,
  pagination?: PaginationState,
  filter?: { componentId?: string; show_explorer?: boolean }
): Promise<TileComponentsResponse> => {
  let supabaseRef = supabaseClient
    .from("tiles_components")
    .select("*,component_id(*)", { count: "estimated", head: false })
    .order("id", { ascending: false });

  if (pagination?.limit) {
    supabaseRef = supabaseRef.limit(pagination.limit);
  }
  if (filter?.componentId) {
    supabaseRef = supabaseRef.eq("component_id.id", filter.componentId);
  }
  if (filter?.show_explorer) {
    supabaseRef = supabaseRef.eq("show_explorer", true);
  }
  if (!pagination?.limit && pagination?.currentPage && pagination?.pageSize) {
    const pageIndex = pagination.currentPage - 1;
    const pageSize = pagination.pageSize;
    supabaseRef = supabaseRef.range(pageIndex * pageSize, (pageIndex + 1) * pageSize - 1);
  }
  const { data, error, count } = await supabaseRef;
  return { data, error, count: count || undefined };
};

export const getTileComponentByComponentId = async (
  supabaseClient: SupabaseClient,
  componentId: string
): Promise<TileComponentsResponse> => {
  const { data, error } = await supabaseClient
    .from("tiles_components")
    .select("*,component_id(*)")
    .eq("component_id", componentId);

  return { data, error };
};

export const getColumnTemplateById = async (
  supabaseClient: SupabaseClient,
  id: string,
  organizationId?: string
): Promise<ColumnTemplateResponse> => {
  if (!organizationId || !id) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_page_column_templates")
    .select("id, name, cell_type, config")
    .eq("id", id)
    .eq("org_id", organizationId);
  return { data, error };
};

export const getTileReviewsData = async (supabaseClient: SupabaseClient): Promise<ReviewsResponse> => {
  const supabaseSelectRef = supabaseClient
    .from("reviews")
    .select("*")
    .eq("website_display", true)
    .eq("is_active", true)
    .order("sort", { ascending: false });
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};

const getPaginatedTileGalleryData = async (
  supabaseClient: SupabaseClient,
  {
    tableName,
    sorting,
    globalSort,
    columns,
    tableFiltersOption,
    filters,
    pagination
  }: {
    tableName: string;
    sorting?: SortItem[];
    columns: TableColumnType[];
    tableFiltersOption?: { filters: TableFilterType[] };
    filters?: TableFilterType[];
    pagination?: PaginationState;
    globalSort?: SortItem[];
  }
) => {
  const filesResp = await getTableData({
    tableName,
    columns,
    tableFiltersOption,
    globalSort,
    sorting,
    supabaseClient,
    filters,
    pagination
  });

  return filesResp;
};

export const getTileGalleryData = async (
  supabaseClient: SupabaseClient,
  spaceName?: string
): Promise<TileGalleryResponse> => {
  const { data: finalPageData } = await getPageByPath(
    supabaseClient,
    BUILDAPPEAL_GALLERY_COMPONENT_PAGE_PATH,
    USER_TYPE.STAFF
  );

  if (!finalPageData) {
    return { data: [], error: null };
  }
  const viewConfig = finalPageData?.views?.find((view) => view.viewType === ViewOption.CARD);
  const finalSorting = [];
  const filters: TableFilterType[] = [];
  if (spaceName) {
    const spaceColumn = finalPageData?.columns?.find(
      (col) => col.isLookup && col.lookupPath?.[0].lookupTableName === "spaces"
    );
    if (spaceColumn) {
      filters.push({
        ...spaceColumn,
        column: spaceColumn,
        filterOperator: "equals",
        filterValue: spaceName,
        filterField: "space",
        isFilterTextSearch: true,
        useFilterLookupColumn: true
      });
    }
  }

  if (
    viewConfig?.additionalConfig?.sortDragAndDrop?.enabled &&
    viewConfig?.additionalConfig?.sortDragAndDrop?.columnToUpdate
  ) {
    const colToSort = finalPageData?.columns?.find(
      (col) => col.id === viewConfig?.additionalConfig?.sortDragAndDrop?.columnToUpdate
    );
    if (colToSort) {
      finalSorting.push({
        id: colToSort.name,
        desc: false,
        col: colToSort
      });
    }
  }

  const allRecords = [];
  // fetch first page
  const filesResp = await getPaginatedTileGalleryData(supabaseClient, {
    tableName: finalPageData.table_name,
    columns: generateFinalDataColumns({
      columns: finalPageData?.columns || [],
      view: ViewOption.CARD,
      config: viewConfig,
      skipRecordTypes: true
    }),
    tableFiltersOption: finalPageData?.pageFilters,
    globalSort: finalPageData?.page_config?.globalSort,
    sorting: finalSorting,
    filters,
    pagination: {
      currentPage: 1,
      pageSize: 200
    }
  });
  const { count, data, error } = filesResp;

  if (error) {
    return filesResp;
  }

  // add first page records to the Array
  if (data && count) {
    allRecords.push(...data);
  }

  if (count) {
    const totalPages = Math.ceil(count / 200);
    // if pages more than 1
    if (totalPages > 1) {
      const fileQueries = [];
      for (let page = 2; page <= totalPages; page++) {
        fileQueries.push(
          getPaginatedTileGalleryData(supabaseClient, {
            tableName: finalPageData.table_name,
            columns: generateFinalDataColumns({
              columns: finalPageData?.columns || [],
              view: ViewOption.CARD,
              config: viewConfig,
              skipRecordTypes: true
            }),
            tableFiltersOption: finalPageData?.pageFilters,
            globalSort: finalPageData?.page_config?.globalSort,
            sorting: finalSorting,
            filters,
            pagination: {
              currentPage: page,
              pageSize: 200
            }
          })
        );
      }
      const fileQueriesResp = await Promise.all(fileQueries);
      fileQueriesResp.forEach((queryResp) => {
        if (queryResp.data) {
          allRecords.push(...queryResp.data);
        }
      });
    }
  }

  return { data: allRecords, error: null };
};

// Checks by subdomain if domain is renovate, else is airstudio
export const getIsStaffSubdomainForHostAndOrgId = async (
  supabaseClient: SupabaseClient,
  host: string
): Promise<{ isStaffSubdomain: boolean; orgId?: string; isRenovateDomain?: boolean }> => {
  if (!host) {
    return { isStaffSubdomain: false };
  }
  const hostParts = host.split(".");
  let subDomain = "";
  let domain = "";
  if (hostParts.length === 3) {
    // Has subdomain
    subDomain = hostParts[0];
    domain = hostParts[1] + "." + hostParts[2];
  } else if (hostParts.length === 2) {
    // No subdomain
    domain = hostParts[0] + "." + hostParts[1];
  }
  const isRenovateDomain = hostParts.includes("renovate");
  if (IS_VERVEL_PREVIEW) {
    return {
      isStaffSubdomain: subDomain.startsWith("airstudio") || domain.startsWith("airstudio") || subDomain === "airstudio"
    };
  }
  if (IS_LOCAL_DEV && !isRenovateDomain) {
    return {
      isStaffSubdomain: subDomain.startsWith("airstudio") || domain.startsWith("airstudio") || subDomain === "app",
      orgId: subDomain === "app" ? "2" : undefined
    };
  }
  let finalData = null;
  if (isRenovateDomain) {
    const { data, error } = await supabaseClient
      .from("organizations")
      .select("id,subdomain")
      .eq("subdomain", subDomain)
      .eq("domain", "renovate.studio")
      .maybeSingle();
    if (error) {
      return { isStaffSubdomain: false };
    }
    finalData = data;
  } else {
    const { data, error } = await supabaseClient
      .from("organizations")
      .select("id,subdomain")
      .eq("domain", domain)
      .maybeSingle();
    if (error) {
      return { isStaffSubdomain: false };
    }
    finalData = data;
  }

  return {
    isStaffSubdomain: finalData?.subdomain ? finalData.subdomain === subDomain : false,
    orgId: finalData?.id,
    isRenovateDomain
  };
};

export const getMenuById = async (
  supabaseClient: SupabaseClient,
  id: string,
  organizationId?: string
): Promise<MenusResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  const { data, error } = await supabaseClient
    .from("ui_menus")
    .select(
      `*, 
      ui_menu_items(*, 
        ui_page_id(id, path, title, default_view, mobile_default_view, metabase_dashboard_id, page_type, is_admin_page), 
        ui_menu_actions(id, action_properties, wait_for_success, has_divider, sort, ui_action_id(name, icon,type))
      ), 
      pages:pages!pages_menu_id_fkey(id,table_name, path,views:ui_pages_views(id,default_column_section_id,is_custom,view:views_id(*), tabs:ui_pages_views_tabs(id,tab:ui_tab_id(id,tab_page_id(path),ui_menu_item_id))))`
    )
    .eq("id", id)
    .eq("is_active", true)
    .eq("ui_menu_items.is_active", true)
    .eq("ui_menu_items.org_id", organizationId)
    .eq("org_id", organizationId)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true });
  return { data, error };
};

export const getMenusByPage = async (
  supabaseClient: SupabaseClient,
  pageId: string | null,
  userType?: string,
  orgId?: string
): Promise<MenusResponse> => {
  if (!orgId) {
    return {
      data: null,
      error: {
        message: "Invalid organization id",
        details: "Organization id is missing for the user",
        code: "ORGINVALID",
        hint: "Please check user details or contact admin"
      }
    };
  }
  let supabaseClientQuery = supabaseClient
    .from("ui_menus")
    .select(
      `*, 
    ui_menu_items!inner(*,
     ui_page_id(id, path, title, default_view, mobile_default_view, table_name, metabase_dashboard_id, page_type, is_admin_page), 
      ui_menu_actions(id, action_properties, wait_for_success, has_divider, sort,ui_action_id(name, icon, type))
    )
    
     ${pageId ? ",pages:pages!pages_menu_id_fkey!inner(id,table_name, path,views:ui_pages_views(id,default_column_section_id,is_custom,view:views_id(*), tabs:ui_pages_views_tabs(id,tab:ui_tab_id(id,add_page_id,default_add_expanded,tab_page_id(path),ui_menu_item_id))))" : ""}
     )
   )`
    )
    .eq("ui_menu_items.org_id", orgId)
    .eq("org_id", orgId);

  if (!pageId) {
    supabaseClientQuery = supabaseClientQuery.is("is_global", true);
  } else if (pageId) {
    supabaseClientQuery = supabaseClientQuery.in("pages.id", [pageId]);
  }

  const { data, error } = await supabaseClientQuery
    .eq("is_active", true)
    .in("user_type", [userType, "All"])
    .eq("ui_menu_items.is_active", true)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true });

  // @ts-ignore
  return { data, error };
};

export const getGlobalMenu = async (
  supabaseClient: SupabaseClient,
  userType: string,
  userOrganizationId: string
): Promise<MenuResponse> => {
  if (!userOrganizationId) {
    return {
      data: null,
      error: {
        message: "Invalid organization id",
        details: "Organization id is missing for the user",
        code: "ORGINVALID",
        hint: "Please check user details or contact admin"
      }
    };
  }
  const response = await supabaseClient
    .from("ui_menus")
    .select(
      "is_global, position, ui_menu_items!inner(*, ui_page_id(id, path, title, default_view, mobile_default_view, table_name, metabase_dashboard_id, page_type, is_admin_page, views), ui_menu_actions(id, action_properties, wait_for_success, has_divider, sort, ui_action_id(name, icon, type)))"
    )
    .eq("is_active", true)
    .eq("is_global", true)
    .in("user_type", [userType, "All"])
    .eq("ui_menu_items.is_active", true)
    .eq("ui_menu_items.org_id", userOrganizationId)
    .eq("org_id", userOrganizationId)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true })
    .maybeSingle();

  // @ts-ignore
  return response;
};

export const getGlobalMenuLite = async (
  supabaseClient: SupabaseClient,
  {
    userType,
    baseTableColumnNames,
    additionalColumns,
    organizationId
  }: {
    userType: string;
    isAdminMenu?: boolean;
    baseTableColumnNames?: string[];
    additionalColumns?: TableColumnType[];
    organizationId?: string;
  }
): Promise<MenuResponse> => {
  let select = "id,is_global,is_active,user_type";
  if (baseTableColumnNames?.length) {
    select += "," + baseTableColumnNames.join(",");
  }

  if (additionalColumns?.length) {
    select +=
      "," +
      constructSupabaseSelect({
        columns: additionalColumns,
        baseTableName: "pages"
      });
  }

  const response = await supabaseClient
    .from("ui_menus")
    .select(select)
    .eq("is_active", true)
    .eq("is_global", true)
    .eq("org_id", organizationId)
    .in("user_type", [userType, "All"])
    .maybeSingle();

  return response as unknown as MenuResponse;
};

export const getRecordMenu = async (
  supabaseClient: SupabaseClient,
  userType: string,
  pagePath: string,
  orgId?: string
): Promise<MenuResponse> => {
  const { data, error } = await supabaseClient
    .from("ui_menus")
    .select(
      "*, ui_menu_items!inner(*, ui_page_id(id, path, title, default_view, mobile_default_view, table_name, metabase_dashboard_id, page_type, is_admin_page), ui_menu_actions(id, action_properties, wait_for_success, has_divider, sort, ui_action_id(name, icon, type))),pages:pages!pages_menu_id_fkey!inner(id,table_name, path,views:ui_pages_views(id,default_column_section_id,is_custom,view:views_id(*), tabs:ui_pages_views_tabs(id,tab:ui_tab_id(tab_page_id(path),ui_menu_item_id))))"
    )
    .eq("is_active", true)
    .eq("is_global", false)
    .in("pages.path", [pagePath])
    .in("user_type", [userType, "All"])
    .eq("ui_menu_items.is_active", true)
    .eq("ui_menu_items.org_id", orgId)
    .eq("org_id", orgId)
    .order("sort_order", { referencedTable: "ui_menu_items", ascending: true })
    .maybeSingle();

  return { data, error };
};

export const getAllPageViews = async (supabaseClient: SupabaseClient, id?: string, organizationId?: string) => {
  if (!id || !organizationId) return Promise.resolve({ data: undefined, error: null });
  const { data, error } = await supabaseClient
    .from("pages")
    .select(
      `
  id,path,is_active,head_title,page_type,is_default_table_page,
  views:ui_pages_views(id,default_column_section_id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*))
  `
    )
    .eq("id", id)
    .eq("org_id", organizationId);
  if (!data?.[0]) return Promise.resolve({ data: undefined, error });

  // @ts-ignore
  const finalPageData = mapApiPageToPage(data[0]);
  return { data: finalPageData, error: null };
};

export const getPageViewAndColumns = async ({
  supabaseClient,
  viewType,
  pageSlug,
  userType,
  id,
  organizationId
}: {
  supabaseClient: SupabaseClient;
  viewType: ViewOption;
  pageSlug: string;
  userType: USER_TYPE;
  id?: string;
  organizationId?: string;
}) => {
  if (!pageSlug || !viewType || !organizationId) return Promise.resolve({ data: undefined, error: null });

  let supabaseRef = supabaseClient
    .from("pages")
    .select(
      `
    id,is_active,page_config,created_in_path,default_view,default_empty_view,has_error,head_title,is_admin_page,mobile_default_view,page_type,parent_id,path,is_default_table_page,redirect_to,skip_record_sidebar_view,sort,static_content,table_name,title,user_type,
    columns:ui_pages_columns(*,
      lookupFilters:lookup_filter_id(id,
        columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null,filter_db_type)
      ),
      column:columns_id(*,
        ui_pages_views_columns(*, view:pages_views_id(id,pages_id,views_id(id,view_type,is_default,additional_config)))
      )
    ),
    views:ui_pages_views(id,default_column_section_id,is_custom,view:views_id(*),columns:ui_pages_views_columns(*),
      tabs:ui_pages_views_tabs(id,page_view_id,created_at,is_child,is_hidden,
        tab:ui_tabs(
          id,
          tab_page_id(id,title,path,table_name),default_view,sidebar_default_view,title,icon,filter_relations,
          sort,
          visibility,
          ui_menu_item_id,
          default_add_expanded,
          show_record_count
        )
      ))`
    )
    .eq("views.view.is_active", true)
    .eq("views.view.view_type", viewType)
    .eq("user_type", userType)
    .eq("org_id", organizationId);

  if (id) {
    supabaseRef = supabaseRef.eq("id", id);
  } else {
    supabaseRef = supabaseRef.eq("path", pageSlug?.startsWith("/") ? pageSlug : `/${pageSlug}`);
  }
  const { data: pageData, error } = await supabaseRef;
  if (!pageData?.[0]) return Promise.resolve({ data: undefined, error });

  // @ts-ignore
  const finalPageData = mapApiPageToPage(pageData[0]);
  return { data: finalPageData, error: null };
};

export const getPageActionsByPathOrId = async ({
  supabaseClient,
  pageId,
  pagePath,
  userType,
  excludePageDetails,
  organizationId
}: {
  supabaseClient: SupabaseClient;
  userType: USER_TYPE;
  pageId?: string;
  pagePath?: string;
  excludePageDetails?: boolean;
  organizationId?: string;
}) => {
  if ((!pageId && !pagePath) || !organizationId) return Promise.resolve({ data: undefined, error: null });
  let baseSelect = `id,ui_pages_actions(id,ui_views_id, ui_action_id(id, name, type, icon, requires_confirmation, has_extended_menu), sort_order, action_properties, page_id, visibility, bulk_sort_order, wait_for_success, has_divider, is_exposed, conditionalViewRules:conditional_view_rule(id,rule_config,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)))`;

  if (!excludePageDetails) {
    baseSelect +=
      ",is_active,page_config,default_view,default_empty_view,is_admin_page,mobile_default_view,page_type,path,is_default_table_page,skip_record_sidebar_view,sort,table_name,title,user_type,pageFilters:page_filters_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))";
  }
  let supabaseRef = supabaseClient
    .from("pages")
    .select(baseSelect)
    .eq("user_type", userType)
    .eq("org_id", organizationId);

  if (pageId) {
    supabaseRef = supabaseRef.eq("id", pageId);
  } else if (pagePath) {
    supabaseRef = supabaseRef.eq("path", pagePath.startsWith("/") ? pagePath : `/${pagePath}`);
  }

  const { data: pageData, error } = await supabaseRef;
  if (!pageData?.[0]) return Promise.resolve({ data: undefined, error });

  return { data: pageData[0] as RecordItem, error: null };
};

export const getBASchema = async (fullUrl: string, isAudit?: boolean, resetCache?: boolean) => {
  if (!fullUrl) return Promise.resolve(undefined);
  let options: { headers?: RecordItem; next?: RecordItem } = {};

  if (resetCache) {
    options = {
      headers: {
        "ba-cache": "reset"
      },
      next: { revalidate: 0 }
    };
  } else {
    options = {
      next: { revalidate: 7200 }
    };
  }

  const result = await fetch(`${fullUrl}/api/admin/ba_schema`, { ...options }).then((res) => res.json());
  return result;
};

export const getBASchemaKvCache = async (url: string) => {
  try {
    return getBASchema(url);
  } catch (error) {
    console.log("🚀 ~ getBASchemaKvCache ~ error:", error);
  }

  return null;
};

export const getAlternateSchema = async (origin: string, alternateSchema: string) => {
  if (!alternateSchema || !origin || !origin.replace("https://", "").replace("http://", "").startsWith("airstudio")) {
    return Promise.resolve(undefined);
  }
  if (alternateSchemaCache?.[alternateSchema]) {
    return Promise.resolve(alternateSchemaCache[alternateSchema]);
  }

  const options = {
    headers: {
      "Accept-Profile": alternateSchema,
      ApiKey: supabaseAnonKey || ""
    }
  };
  const result = await fetch(`${supabaseUrl}/rest/v1/`, { ...options }).then((res) => res.json());
  return result;
};

export const getRecordTypes = async (supabaseClient: SupabaseClient): Promise<RecordTypesResponse> => {
  const { data, error } = await supabaseClient
    .from("record_types")
    .select(
      "id,type,tablename,page_id,config,schema,page:page_id(id,path,title,table_name),lookup_column(id,lookup_path),image_column(id,lookup_path,page_columns:ui_pages_columns(id,page_id,lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null))))"
    );

  return {
    data: data?.map((recordType) => ({
      ...recordType,
      image_column: recordType?.image_column
        ? {
            ...recordType?.image_column,
            lookup_filters: (recordType?.image_column as RecordItem)?.page_columns?.find(
              (col: RecordItem) => col.page_id === recordType.page_id
            )?.lookupFilters
          }
        : undefined
    })) as RecordItem[] as ApiRecordType[],
    error
  };
};

export const getAllCustomViews = async (
  supabaseClient: SupabaseClient,
  organizationId?: string
): Promise<CustomViewsResponse> => {
  if (!organizationId) return { data: [], error: null };
  const { data, error } = await supabaseClient
    .from("ui_views")
    .select("id,title,view_type,columns:ui_views_columns(id,column:columns_id(id,header,name,is_lookup))")
    .eq("is_active", true)
    .eq("is_custom", true)
    .eq("org_id", organizationId);

  return { data: data as RecordItem[] as ApiView[], error };
};

export const getCustomViewById = async (
  supabaseClient: SupabaseClient,
  id?: string,
  organizationId?: string
): Promise<TableViewConfigResponse> => {
  if (!id || !organizationId) return { data: undefined, error: null };

  const { data, error } = await supabaseClient
    .from("ui_views")
    .select(
      `*,
      columns:ui_views_columns(id,is_active,
        column:columns_id(*),
        lookupFilters:lookup_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,value,filter_lookup_path,lookup_column_name_field,filter_group,include_null)),
        columnFilters:column_options_filter_id(id,columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,filter_db_type,value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)),
        pageColumn:pages_columns_id(id,page_id,is_active,
          columnFilters:column_options_filter_id(id,
            columnRules:ui_column_condition(id,pageColumn:ui_page_column_id(*, column:columns_id(id)),field,operator,
              value,filter_db_type,filter_lookup_path,record_id_source,lookup_column_name_field,filter_group,include_null)
            )
          )
      ),
      sections:ui_column_sections(id,title,description,webhook_url,config,sort,is_close,is_admin_only,for_user_id,
        viewColumns:ui_views_columns_sections(id,ui_views_columns_id(columns_id)),
        filter:ui_filters_id(id,
          columnRules:ui_column_condition(id,
            pageColumn:ui_page_column_id(*,is_active,
              column:columns_id(is_active,id)),
              field,operator,filter_db_type,value,is_user_visible,option_value,filter_lookup_path,lookup_column_name_field,filter_group,include_null
            )
          )
        )
      )`
    )
    .eq("id", id)
    .eq("org_id", organizationId);
  // @ts-ignore
  return { data: mapApiViewToTableViewConfig(data?.[0] as ApiView, true), error };
};

export const getOrganizationIdForUserId = async (supabaseClient: SupabaseClient, userId: string) => {
  if (!userId) {
    return { data: null, error: null };
  }

  return supabaseClient.from("people").select("org_id").eq("user_id", userId).maybeSingle();
};

export const getOrganizations = async (supabaseClient: SupabaseClient): Promise<OrganizationsResponse> => {
  const { data, error } = await supabaseClient
    .from("organizations")
    .select("id,subdomain,domain,name, logo:logo_file_id(id,path)")
    .eq("is_active", true);

  return { data: data as any as Organization[], error };
};

export const getOrganizationById = async (
  supabaseClient: SupabaseClient,
  id?: string
): Promise<OrganizationResponse> => {
  if (!id) return { data: null, error: null };

  const { data, error } = await supabaseClient
    .from("organizations")
    .select("id,subdomain,domain,name, logo:logo_file_id(id,path)")
    .eq("id", id)
    .eq("is_active", true)
    .single();

  return { data: data as any as Organization, error };
};

export const getAllColors = async (
  supabaseClient: SupabaseClient,
  organizationId?: string,
  skipOrgIdCheck?: boolean
): Promise<ActionsResponse> => {
  if (!organizationId) {
    return { data: null, error: null };
  }
  let supabaseSelectRef = supabaseClient.from("colors").select();
  if (!skipOrgIdCheck && organizationId) {
    supabaseSelectRef = supabaseSelectRef.eq("org_id", organizationId);
  }
  const { data, error } = await supabaseSelectRef;
  return { data, error };
};
